/*******************************************************************************
 * Niniejszy plik jest częścią pakietu programistycznego QCG.
 * Wszelkie prawa do tego oprogramowania przysługują
 * Instytutowi Chemii Bioorganicznej -
 * Poznańskie Centrum Superkomputerowo-Sieciowe z siedzibą w Poznaniu.
 ******************************************************************************/

import React from 'react';

import axios from '../utils/axios/axiosInstance';

const sendRequest = (method, address, data = {}, retryCount = { "500": 0, "400": -1 }) => axios({
  method,
  url: address,
  data,
  __retryCount: retryCount
});

export const withSendRequest = (Component) => ({ ...props }) => {
  return <Component {...props} sendRequest={sendRequest} />;
};
