/*******************************************************************************
 * Niniejszy plik jest częścią pakietu programistycznego QCG.
 * Wszelkie prawa do tego oprogramowania przysługują
 * Instytutowi Chemii Bioorganicznej -
 * Poznańskie Centrum Superkomputerowo-Sieciowe z siedzibą w Poznaniu.
 ******************************************************************************/

import { Map, fromJS } from 'immutable';

import { LOAD_TEMPLATE } from '../actions/templates';

export const templateReducer  = (state = Map(), action) => {
  switch (action.type) {

  case LOAD_TEMPLATE:

    return state.set(action.payload.id, fromJS(action.payload.template));

  default:
    return state;
  }
};
