/*******************************************************************************
 * Niniejszy plik jest częścią pakietu programistycznego QCG.
 * Wszelkie prawa do tego oprogramowania przysługują
 * Instytutowi Chemii Bioorganicznej -
 * Poznańskie Centrum Superkomputerowo-Sieciowe z siedzibą w Poznaniu.
 ******************************************************************************/

import { createStore, applyMiddleware, compose } from 'redux';
import { combineReducers } from 'redux';
import thunk from 'redux-thunk';
import { routerMiddleware, connectRouter } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import { reducer as toastrReducer } from 'react-redux-toastr';

import { __env } from '../../envloader';
import { loginReducer } from '../../oidc-middleware/reducers/loginReducer';
import { getOidcMiddleware } from '../../oidc-middleware/middleware';

import { columnsReducer } from '../../qcg-ui/reducers/columns';
import { resourcesReducer } from '../../qcg-ui/reducers/resources';
import { chartsReducer } from '../../qcg-ui/reducers/charts';
import { templateReducer } from '../../qcg-ui/reducers/templates';

const history = createBrowserHistory({ basename: __env.PUBLIC_URL });

const reducers = {
  router: connectRouter(history),
  login: loginReducer,
  columns: columnsReducer,
  resources: resourcesReducer,
  charts: chartsReducer,
  templates: templateReducer,
  toastr: toastrReducer,
};

const rootReducer = combineReducers(reducers);

// as found on: https://github.com/zalmoxisus/redux-devtools-extension#12-advanced-store-setup
const composeEnhancers =
	typeof window === 'object' &&
		window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ?
	  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
	    // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
	  }) : compose;

const configureStore = (initialState, axiosAuthInstance) => {
  let middlewares = [ thunk, getOidcMiddleware(axiosAuthInstance) ];

  if (__env.NODE_ENV === 'development') {
    // related resources for that strange .default below
    // https://github.com/evgenyrodionov/redux-logger/issues/98
    // https://github.com/59naga/babel-plugin-add-module-exports
    // https://github.com/babel/babel/issues/2212

    if (__env.USE_REDUX_LOGGER) {
      const createLogger = require('redux-logger');

      const logger = createLogger({
        stateTransformer: (state) => (JSON.parse(JSON.stringify(state)))
      });
      middlewares = [ ...middlewares, logger ];
    }
  }

  const rootReducerWithRouter = connectRouter(history)(rootReducer);
  const middleware = applyMiddleware(...middlewares, routerMiddleware(history));
  const store = createStore(rootReducerWithRouter, initialState, composeEnhancers(middleware));

  return {
    store,
    history,
  };
};

export default configureStore;
