/*******************************************************************************
 * Niniejszy plik jest częścią pakietu programistycznego QCG.
 * Wszelkie prawa do tego oprogramowania przysługują
 * Instytutowi Chemii Bioorganicznej -
 * Poznańskie Centrum Superkomputerowo-Sieciowe z siedzibą w Poznaniu.
 ******************************************************************************/

import { List, Map, fromJS } from 'immutable';
import { CHANGE_COLUMNS } from '../actions/columns';

const columnsDefaultState = List([
  Map({
    key: "id",
    visibility: true
  }),
  Map({
    key: "user",
    visibility: true
  }),
  Map({
    key: "state",
    visibility: true
  }),
  Map({
    key: "queue",
    visibility: true
  }),
  Map({
    key: "submit_time",
    visibility: true
  }),
  Map({
    key: "updated_time",
    visibility: true
  }),
  Map({
    key: "nodes",
    visibility: false
  }),
  Map({
    key: "actions",
    visibility: true
  })
]);

export const columnsReducer  = (state = columnsDefaultState, action) => {
  switch (action.type) {

  case CHANGE_COLUMNS:
    return fromJS(action.payload.columns);


  default:
    return state;
  }
};
