/*******************************************************************************
 * Niniejszy plik jest częścią pakietu programistycznego QCG.
 * Wszelkie prawa do tego oprogramowania przysługują
 * Instytutowi Chemii Bioorganicznej -
 * Poznańskie Centrum Superkomputerowo-Sieciowe z siedzibą w Poznaniu.
 ******************************************************************************/

import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Col, Row } from 'reactstrap';
import moment from 'moment';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';

import Area from '../components/area';
import ClusterManagement from './clusterManagement';
import Charts from '../components/charts';
import Queues from '../components/queues';
import { withSendRequest } from '../../main/hoc/withSendRequest';
import { __env } from '../../envloader';
import { loadResourcesData } from '../actions/resources';
import { loadChartsData } from '../actions/charts';
import RefreshFeature from '../components/refreshFeature';
import { ErrorBoundary } from '../../main/hoc/errorboundary';
import LocalTimeComponent from '../components/localTimeComponent';

@ErrorBoundary(() => {
  return 'Dashboard';
})
@withTranslation()
@connect(
  state => ({
    resources: state.resources,
    charts: state.charts,
  }),
  dispatch => {
    return bindActionCreators({
      loadChartsData: loadChartsData,
      loadResourcesData: loadResourcesData,
    }, dispatch);
  }
)
@withSendRequest
export default class Dashboard extends Component {

  componentDidMount() {
    this.fetchData();
  }

  fetchData = () => {
    this.props.loadChartsData({ sendRequest: this.props.sendRequest });
    this.props.loadResourcesData({ sendRequest: this.props.sendRequest });
  };

  getLatestTaskCount = (intervalData, state) => {
    return intervalData.get(intervalData.size - 1) ? intervalData.get(intervalData.size - 1)[state] : 0;
  };

  render() {
    const { charts, resources, t } = this.props;
    const startedFromResources = resources.getIn([ 'config','started' ]);
    let startedString = "?";
    if (startedFromResources) {
      const started = moment(startedFromResources);
      startedString = started.format("DD.MM.Y HH:mm:ss")+" ("+started.fromNow()+")";
    }
    return (
      <div>
        <div className='refresh-feature-box mb-2 right-panel'>
          <RefreshFeature
            minValue={10}
            maxValue={100}
            default={30}
            fetchData={this.fetchData}
          />
        </div>
        <div id="dashboard" >
          <Row>
            <Col sm="6" className="align-self-stretch">
              <Row>
                <Col sm="6">
                  <Row>
                    <Col sm="12">
                      <Area title={t("cluster_data")}>
                        <div className="line">
                          {t('address')}: <span className="font-weight-bold">{__env.QCG_API_URL}</span>
                        </div>
                        <div className="line">
                          {t('version')}: <span className="font-weight-bold">{resources.getIn([ 'config','version' ])}</span>
                        </div>
                        <div className="line">
                          {t('number_of_nodes')}: <span className="font-weight-bold">{resources.getIn([ 'nodes','node_stats','total','nodes' ])}</span>
                        </div>
                        <div className="line">
                          {t('number_of_cores')}: <span className="font-weight-bold">{resources.getIn([ 'nodes','node_stats','total','cpus' ])}</span>
                        </div>
                        <div className="line">
                          {t('memory')}: <span className="font-weight-bold">{resources.getIn([ 'nodes','node_stats','total','memory' ])}KB</span>
                        </div>
                        <div className="line">
                          {t('started')}: <LocalTimeComponent id='cluster-start-date' className="font-weight-bold">{startedString}</LocalTimeComponent>
                        </div>
                      </Area>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm="12">
                      <Area title={t("cluster_stats")}>
                        <div className="line font-weight-bold">
                          {t('pending_tasks_in')}:
                        </div>
                        <ul>
                          <li>
                            {t('current_hour') + ': '}
                            <span className="font-weight-bold">{this.getLatestTaskCount(charts.get('hourIntervalData'), 'PENDING')}</span>
                          </li>
                          <li>
                            {t('current_day') + ': '}
                            <span className="font-weight-bold">{this.getLatestTaskCount(charts.get('dayIntervalData'), 'PENDING')}</span>
                          </li>
                          <li>
                            {t('current_week') + ': '}
                            <span className="font-weight-bold">{this.getLatestTaskCount(charts.get('weekIntervalData'), 'PENDING')}</span>
                          </li>
                        </ul>
                        <div className="line font-weight-bold">
                          {t('finished_tasks_in')}:
                        </div>
                        <ul>
                          <li>
                            {t('current_hour') + ': '}
                            <span className="font-weight-bold">{this.getLatestTaskCount(charts.get('hourIntervalData'), 'FINISHED')}</span>
                            {this.getLatestTaskCount(charts.get('hourIntervalData'), 'FAILED') > 0 &&
                            <span className="font-weight-bold">
                              &nbsp;({t('failed_count', { count: this.getLatestTaskCount(charts.get('hourIntervalData'), 'FAILED') })})
                            </span>
                            }
                          </li>
                          <li>
                            {t('current_day') + ': '}
                            <span className="font-weight-bold">{this.getLatestTaskCount(charts.get('dayIntervalData'), 'FINISHED')}</span>
                            {this.getLatestTaskCount(charts.get('dayIntervalData'), 'FAILED') > 0 &&
                            <span className="font-weight-bold">
                              &nbsp;({t('failed_count', { count: this.getLatestTaskCount(charts.get('dayIntervalData'), 'FAILED') })})
                            </span>
                            }
                          </li>
                          <li>
                            {t('current_week') + ': '}
                            <span className="font-weight-bold">{this.getLatestTaskCount(charts.get('weekIntervalData'), 'FINISHED')}</span>
                            {this.getLatestTaskCount(charts.get('weekIntervalData'), 'FAILED') > 0 &&
                            <span className="font-weight-bold">
                              &nbsp;({t('failed_count', { count: this.getLatestTaskCount(charts.get('weekIntervalData'), 'FAILED') })})
                            </span>
                            }
                          </li>
                        </ul>
                      </Area>
                    </Col>
                  </Row>
                </Col>
                <Col sm="6">
                  <Area title={t("cluster_management")}>
                    <ClusterManagement/>
                  </Area>
                </Col>
              </Row>
              <Row>
                <Col sm="12">
                  <Area title={t('tasks')}>
                    <Queues fetchData={this.fetchData}/>
                  </Area>
                </Col>
              </Row>
            </Col>
            <Col sm="6" className="align-self-stretch">
              <Area title={t("cluster_charts")}>
                <Charts/>
              </Area>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

Dashboard.propTypes = {
  t: PropTypes.func, //HOC
  loadChartsData: PropTypes.func, //HOC
  loadResourcesData: PropTypes.func, //HOC
  resources: PropTypes.object, //HOC
  charts: PropTypes.object, //HOC
  sendRequest: PropTypes.func, //HOC
};
