/*******************************************************************************
 * Niniejszy plik jest częścią pakietu programistycznego QCG.
 * Wszelkie prawa do tego oprogramowania przysługują
 * Instytutowi Chemii Bioorganicznej -
 * Poznańskie Centrum Superkomputerowo-Sieciowe z siedzibą w Poznaniu.
 ******************************************************************************/

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Form from 'react-jsonschema-form-bs4';
import { withTranslation } from 'react-i18next';
import applyNavs from 'react-jsonschema-form-pagination-bs4';

import { processForm, validateForm } from './formUtils';
import CustomNavs from './customNavs';
import CustomFileWidget from '../widgets/customFileWidget';

@withTranslation()
export default class SubmitForm extends Component {
  constructor(props) {
    super(props);
    this.state = processForm(this.props.templateSchema, this.props.templateUiSchema, this.props.templateSchema, this.props.templateUiSchema, {});
    this.FormWithTabs = applyNavs(Form, CustomNavs);
  }

  validate = (formData, errors) => {
    return validateForm(this.state.uiSchema, formData, errors);
  };

  handleChange = data => {
    const newState = processForm(this.props.templateSchema, this.props.templateUiSchema, this.state.schema, this.state.uiSchema, data.formData);
    this.setState(newState);
  };

  render() {
    const { FormWithTabs } = this;
    return (
      <FormWithTabs
        schema={this.state.schema}
        uiSchema={this.state.uiSchema}
        widgets={{ ExternalFileWidget: CustomFileWidget }}
        formData={this.state.formData}
        onChange={this.handleChange}
        onSubmit={this.props.onSubmit}
        onError={param => {console.error("error", param);}}
        validate={this.validate}
      />
    );
  }
}

SubmitForm.propTypes = {
  templateSchema: PropTypes.object.isRequired,
  templateUiSchema: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
};
