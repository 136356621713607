import React from 'react';
import { Button } from 'reactstrap';

import Login from '../../main/containers/login';
import Footer from './footer';

const howToStart = () => {
  return (
    <div className="howToStart">
      <div className="main text-center">
        <h1>How To Start</h1>
        <p>In case of any questions related to SaaS portal, please<br /> contact PSNC staf at <a href="mailto:eocoe@psnc.pl">eocoe@psnc.pl</a></p>
      </div>
      <div className="steps">
        <div className="step">
          <h3>01</h3>
          <hr />
          <p>Register for an account in EoCoE SaaS Portal.</p>
          <Login register />
        </div>
        <div className="step">
          <h3>02</h3>
          <hr />
          <p>
            Send and email to eocoe@psnc.pl with short information about you and your interest in using EoCoE applications. 
            This step is necessary to unblock access to HPC and run real jobs.
          </p>
          <Button href="mailto:eocoe@psnc.pl">Send e-mail</Button>
        </div>
        <div className="step">
          <h3>03</h3>
          <hr />
          <p>Check EoCoE youtube account for video pressentations of EoCoE software and tools.</p>
          <Button href="https://www.youtube.com/channel/UCfgL4SiiztRrquNMRY_h8Nw">Go to youtube</Button>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default howToStart;