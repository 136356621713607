/*******************************************************************************
 * Niniejszy plik jest częścią pakietu programistycznego QCG.
 * Wszelkie prawa do tego oprogramowania przysługują
 * Instytutowi Chemii Bioorganicznej -
 * Poznańskie Centrum Superkomputerowo-Sieciowe z siedzibą w Poznaniu.
 ******************************************************************************/

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Table } from 'reactstrap';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withTranslation } from 'react-i18next';
import { FaTimes } from 'react-icons/fa';
import { FaCheck } from 'react-icons/fa';
import { MdDragHandle } from 'react-icons/md';
import { FaEye } from 'react-icons/fa';
import { FaEyeSlash } from 'react-icons/fa';

import { changeColumns } from '../../actions/columns';
import { ErrorBoundary } from '../../../main/hoc/errorboundary';

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [ removed ] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};


@ErrorBoundary((props) => {
  return `${props.t('columns_modal')}`;
}, true)
@withTranslation()
@connect(
  state => ({
    columnsStore: state.columns,
  }),
  dispatch => {
    return bindActionCreators({
      changeColumns: changeColumns,
    }, dispatch);
  }
)
export default class ColumnsModal extends Component {
  state = {
    items: this.props.columnsStore.toJS(),
  };

  close = () => {
    this.props.onClose();
  };

  success = () => {
    this.props.changeColumns({ columns: this.state.items });
    this.props.onClose();
  };

  onDragEnd = result => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items = reorder(
      this.state.items,
      result.source.index,
      result.destination.index
    );

    this.setState({ items });
  };

  renderVisibility(item, index) {
    return (
      <span id={item.key + "-visibility-switch"} data-testid={item.key + "-visibility-switch-test"} onClick={() => {
        let items = this.state.items;
        items[index].visibility = !items[index].visibility;
        this.setState({ items });
      }}>
        {item.visibility ? <FaEye/> : <FaEyeSlash/>}
      </span>
    );
  }

  render() {
    const { t } = this.props;
    return (
      <div>
        <Modal isOpen={true} toggle={this.close} size="lg">
          <ModalHeader id="columns-modal-header" toggle={this.close}>{t("columns")}</ModalHeader>
          <ModalBody>
            <Table>
              <thead>
                <tr>
                  <th>{t("field")}</th>
                  <th>{t("verbose_name")}</th>
                  <th className="visibility-column">{t("visibility")}</th>
                  <th className="order-column">{t("change_order")}</th>
                </tr>
              </thead>

              <DragDropContext onDragEnd={this.onDragEnd}>
                <Droppable droppableId="droppable">
                  {(provided, snapshot) => (
                    <tbody
                      ref={provided.innerRef}
                      className={snapshot.isDraggingOver ? "droppable-list dragging-over" : "droppable-list"}
                    >
                      {this.state.items.map((item, index) => (
                        <Draggable key={item.key} draggableId={item.key} index={index}>
                          {(provided, snapshot) => (
                            <tr
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              className={snapshot.isDragging ? "draggable-item dragged" : "draggable-item"}
                            >
                              <td>{item.key}</td>
                              <td>{this.props.t(item.key + "_column")}</td>
                              <td className="visibility-column">{this.renderVisibility(item, index)}</td>
                              <td className="order-column" {...provided.dragHandleProps}><MdDragHandle/></td>
                            </tr>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </tbody>
                  )}
                </Droppable>
              </DragDropContext>

            </Table>
          </ModalBody>
          <ModalFooter>
            <Button id="accept-button" size="sm" color="success" onClick={this.success}><FaCheck/> {t("apply")}</Button>{' '}
            <Button id="cancel-button" size="sm" color="danger" onClick={this.close}><FaTimes/> {t("cancel")}</Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

ColumnsModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  t: PropTypes.func, //HOC
  columnsStore: PropTypes.object, //HOC
  changeColumns: PropTypes.func, //HOC
};
