/*******************************************************************************
 * Niniejszy plik jest częścią pakietu programistycznego QCG.
 * Wszelkie prawa do tego oprogramowania przysługują
 * Instytutowi Chemii Bioorganicznej -
 * Poznańskie Centrum Superkomputerowo-Sieciowe z siedzibą w Poznaniu.
 ******************************************************************************/

import React, { Component } from 'react';
import { Button, Form, Label, Input, Spinner } from 'reactstrap';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Map } from 'immutable';
import { bindActionCreators } from 'redux';

import { withSendRequest } from '../../../main/hoc/withSendRequest';
import { loadResourcesData } from '../../actions/resources';
import * as notify from '../../utils/notify';
import { ErrorBoundary } from '../../../main/hoc/errorboundary';

@ErrorBoundary((props) => {
  return `${props.t('queue_mover')}`;
})
@withTranslation()
@connect(
  state => ({
    queues: state.resources.getIn([ 'queues', 'list' ], Map()),
  }),
  dispatch => {
    return bindActionCreators({
      loadResourcesData: loadResourcesData,
    }, dispatch);
  }
)
@withSendRequest
export default class QueueMover extends Component {
  state = {
    queue: undefined
  };

  componentDidMount() {
    //Load queues if needed
    if (this.props.queues.isEmpty()) {
      this.props.loadResourcesData({ sendRequest: this.props.sendRequest });
    }
  }

  move = () => {
    const { t } = this.props;
    this.props.sendRequest('patch', '/jobs/' + this.props.taskId + '/', { queue: this.state.queue })
      .then(response => {
        //Moving operation successfully started
        notify.success(t('move_operation_success_notification_title'), t('move_operation_success_notification_body') + this.props.taskId);
        this.props.refresh();
      })
      .catch(function (error) {
        //Moving failed
        notify.error(t('move_operation_error_notification_title'), error.message);
        throw error;
      });
  };

  queueChanged = (event) => {
    this.setState({ queue: event.target.value });
  };

  renderQueueOptions = () => {
    return this.props.queues.keySeq().toArray().map(queue =>
      <option key={queue} value={queue}>{queue}</option>
    );
  };

  render() {
    const { loading, t } = this.props;
    const disabled = loading || this.props.currentState !== 'PENDING';
    return (
      <Form className="queue-mover" inline>
        <Label>{t('move_to_queue')}</Label>
        <Input
          defaultValue={"undefined"}
          type="select"
          name="select"
          id="queueSelect"
          bsSize="sm"
          className="mx-1"
          onChange={this.queueChanged}
          disabled={disabled}
        >
          <option hidden disabled value={"undefined"}>{t('select')}</option>
          {this.renderQueueOptions()}
        </Input>
        <Button size="sm" onClick={this.move} disabled={disabled || !this.state.queue}>{t('move')}</Button>
        {loading ? <Spinner color="secondary" size="sm" className="ml-1" /> : null}
      </Form>
    );
  }
}

QueueMover.propTypes = {
  taskId: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  refresh: PropTypes.func.isRequired,
  currentState: PropTypes.string,
  t: PropTypes.func, //HOC
  queues: PropTypes.object, //HOC
  loadResourcesData: PropTypes.func, //HOC
  sendRequest: PropTypes.func, //HOC
};
