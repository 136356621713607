import React, { useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

const IframeModal = (props) => {
  const [ value, setValue ] = useState(undefined);

  window.onmessage = function (e) {
    const pathArray = props.providerData.url.split('/');
    const protocol = pathArray[0];
    const host = pathArray[2];
    const url = protocol + '//' + host;
    if (e.origin === url){
      if (props.providerData.ui_prefix && props.providerData.actual_prefix)
        setValue(e.data.replace(props.providerData.ui_prefix, props.providerData.actual_prefix));
      else
        setValue(e.data);
    }
  };

  return (
    <Modal isOpen={true} size='lg' className="iframe-modal" >
      <ModalHeader toggle={props.onClose}>{props.title}</ModalHeader>
      <ModalBody>
        <div className="iframe-container">
          <iframe title={props.title} src={props.providerData.url} ></iframe>
        </div>
      </ModalBody>
      <ModalFooter className='iframe-modal-footer'>
        {props.providesUrl ?
          [ <p>{props.t('fileSelectorWidget.chosenFile')} {value}</p>,
            <Button onClick={() => props.submitValue(value)}>
              {props.t('fileSelectorWidget.submit')}
            </Button> ]
          :
          <Button onClick={props.onClose}>
            {props.t('close')}
          </Button>
        }
      </ModalFooter>
    </Modal>
  );
};

IframeModal.propTypes = {
  t: PropTypes.func, //HOC
  onClose: PropTypes.func,
  submitValue: PropTypes.func,
  providerData: PropTypes.object.isRequired,
  title: PropTypes.string,
  providesUrl: PropTypes.bool,
};

export default withTranslation()(IframeModal);