import React from 'react';
import { Row, Col } from 'reactstrap';
import PropTypes from 'prop-types';

import LogoImg from './../../assets/img/cropped-logo-eocoe-alta-risoluzione-q-192x192.png';
import PCSSImg from './../../assets/img/footer-logo-pcss.png';
import EUImg from './../../assets/img/eu-funding.png';
import EmailIcon from './../../assets/icons/email.svg';

const Footer = ({ landing }) => (
  <footer className="pb-4">
    <div id="footer-text" className={landing ? "border-top border-dark " : "" + "w-75 mb-w-100 mx-auto mb-2 text-center"}>
      The implementation of the portal was funded from the European Commission Horizon 2020 project No. 824158 (“EoCoE-II”)
      and cofounded from the programme of the Polish Minister of Science and Higher Education entitled "PMW"
      in the years 2019 - 2021; agreement no. 5057/H2020/19/2020/2
    </div>
    <Row noGutters xs="2" md="4" className="col-md-9 col-xs-12 mx-auto">
      <Col className="d-flex justify-content-center align-items-center mt-4">
        <img src={LogoImg} className="eocoe-logo" alt="EoCoE logo"/>
      </Col>
      <Col className="d-flex justify-content-center align-items-center mt-4">
        <img src={EUImg} className="ec-logo" alt="European Commission"/>
      </Col>
      <Col className="d-flex justify-content-center align-items-center mt-4">
        Developed by
        <img src={PCSSImg} className="pcss-logo" alt="PCSS"/>
      </Col>
      <Col className="d-flex justify-content-center align-items-center mt-4">
        <img src={EmailIcon} alt="Email Icon" className="mr-2" />
        <span className="mr-1">Contact: </span>
        <a href="mailto:eocoe@psnc.pl" className="text-success">eocoe@psnc.pl</a>
      </Col>
    </Row>
  </footer>
);

Footer.propTypes = {
  landing: PropTypes.bool,
};

export default Footer;
