/*******************************************************************************
 * Niniejszy plik jest częścią pakietu programistycznego QCG.
 * Wszelkie prawa do tego oprogramowania przysługują
 * Instytutowi Chemii Bioorganicznej -
 * Poznańskie Centrum Superkomputerowo-Sieciowe z siedzibą w Poznaniu.
 ******************************************************************************/

import React, { Component } from 'react';
import { Col, Row, Spinner, Dropdown, DropdownToggle } from 'reactstrap';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { fromJS, Map } from 'immutable';

import Area from '../../components/area';
import { withSendRequest } from '../../../main/hoc/withSendRequest';
import SubmitForm from './submitForm';
import { getCurrentLanguageShortCode } from '../../utils/templates';
import SubmitSuccessModal from './submitSuccessModal';


@withTranslation()
@withSendRequest
export default class Submit extends Component {
  state = {
    templateData: Map(),
    loading: false,
    successId: undefined,
  };

  getTemplateURL = () => {
    //TODO: import url-search-params-polyfill for IE and Opera mini
    const query = new URLSearchParams(this.props.location.search);
    return query && query.get('templateUrl');
  };

  getTemplateData = () => {
    const templateUrl = this.getTemplateURL();
    if (templateUrl) {
      this.setState({ templateData: Map(), loading: true });
      const shortLngCode = getCurrentLanguageShortCode();
      this.props.sendRequest("get", "/templates/render/?templateUrl=" + templateUrl + "&locale=" + shortLngCode)
        .then(response => {
          this.setState({ templateData: fromJS(response.data.templateData), loading: false });
        })
        .catch(error => {
          //TODO: better error handling
          this.setState({ loading: false });
        });
    }
  };

  submit = ({ formData }) => {
    this.props.sendRequest("post", "/jobs/", { template_source: this.state.templateData.get('source').toJS(), template_vars: formData })
      .then(response => {
        this.setState({ successId: response.data.id });
      })
      .catch(error => {
        //TODO: error handling
      });
  };

  componentDidMount() {
    this.getTemplateData();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.location.search !== this.props.location.search) {
      //Query changed
      this.getTemplateData();
    }
  }

  renderTemplateName = () => {
    const templateName = this.state.templateData.getIn([ 'source', 'name' ]);
    return (
      <Dropdown isOpen={false} toggle={()=>{}} inline="true" className="d-inline mx-1" tag={Link} to="/ui/templates">
        <DropdownToggle caret color="info" className="px-2 py-1">
          {templateName || this.props.t('submit_view.choose')}
          &nbsp;
        </DropdownToggle>
      </Dropdown>
    );
  };

  render() {
    return (
      <div className="mt-4">

        {this.state.successId &&
        <SubmitSuccessModal
          id={this.state.successId}
          onClose={()=> {this.setState({ successId: undefined });}}
        />
        }

        <Row>
          <Col sm={{ size: 8, offset: 2 }}>
            <Area title={this.props.t('submit_view.submit_a_job')}>

              <h1 className="text-center mt-2">
                <span>{this.props.t('submit_view.submit_with_template') + ': '}</span>
                { this.renderTemplateName() }
              </h1>

              { !this.state.templateData.isEmpty() &&
                <SubmitForm
                  templateSchema={this.state.templateData.get('schema').toJS()}
                  templateUiSchema={this.state.templateData.get('uiSchema').toJS()}
                  onSubmit={this.submit}
                />
              }

              { this.state.loading &&
              <div className="text-center">
                <Spinner color="primary"/>
              </div>
              }

            </Area>
          </Col>
        </Row>
      </div>
    );
  }
}

Submit.propTypes = {
  t: PropTypes.func, //HOC
  sendRequest: PropTypes.func, //HOC
  location: PropTypes.object, //HOC
};
